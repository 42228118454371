import React from "react";
import { Fragment, useState } from "react";
import i18next from "i18next";

import { marks } from "../../../../utils/marks";

// import classes from "../HomeCodingContents.module.css";
import classes from "./KuraStorage.module.css";

// const textAudioToolUrl = "https://kura-text-audio-tools.spaceeight.net";

function KuraImagePhoto(props) {
  //   const [showDetail, setShowDetail] = useState(false);

  return (
    <Fragment>
      <div>
        <a
          href="https://kura-image-photo.spaceeight.net"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>Kura Image Photo</strong>
        </a>
      </div>
      <div>
        <p>
          Kura Image Photo{" "}
          {i18next.t(
            "kuraStorage20",
            "provides simple storage space for files with various image tools."
          )}
          <br />
          {i18next.t("kuraStorage21", "Images can be shared")} at{" "}
          <a
            href="https://watakura.xyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>watakura</strong>
          </a>
          .
          {/* {i18next.t("kuraStorage14", "provides simple storage space for files and images with image editor.")} */}
        </p>
        <p>
          <div>
            <strong>Image Editor</strong>
          </div>
          <a
            href="https://kura-image-photo.spaceeight.net/image-editor"
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18next.t("kuraStorage15", "Image Editor ")}
          </a>{" "}
          {i18next.t(
            "kuraStorage16",
            "is simple and easy to use for editing image files."
          )}{" "}
          {i18next.t(
            "kuraStorage17",
            "It is possible to edit your image files from both mobile devices and desktop devices."
          )}
        </p>
        <p>
          {i18next.t(
            "kuraStorage18",
            "Image Editor has various functionalities such as cropping, flipping, and rotating, drawing tools on image, and many image filters."
          )}
        </p>
        <p>
          <div>
            <strong>Image Cleaner</strong>
          </div>
          <a
            href="https://kura-image-photo.spaceeight.net/image-cleaner"
            target="_blank"
            rel="noopener noreferrer"
          >
            Image Cleaner
          </a>{" "}
          {i18next.t(
            "kuraStorage22",
            "is an AI-assisted image cleaning tool. It is possible to remove unwanted objects, defects, people from your image."
          )}
          <br />
          {i18next.t(
            "kuraStorage23",
            "Advanced AI technologies enable to finish image cleaning process in less than 10 seconds usually."
          )}
        </p>
        <p>
        <div>
            <strong>Dream Inpainter</strong>
          </div>
          <a
            href="https://kura-dream-inpainter.spaceeight.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dream Inpainter
          </a>{" "}
          {i18next.t('imagePhoto01', 'has image editing tools that include')}
          {' '}
          Kura Dream Inpainter, Background Remover, Illustration Generator.
          <p>
            At Kura Dream Inpainter, 
            {' '}
            {i18next.t('imagePhoto02', 'user can modify specific parts of the image without affecting other parts.')}
            {' '}
            {i18next.t('imagePhoto03', 'Using advanced AI-technologies, selected part of image is replaced with generated new image that matches text prompt description.')}
          </p>
          <p>
            In Background Remover,
            {' '} 
            {i18next.t('imagePhoto04', 'advanced AI-technologies enabled to delete background from image.')}
          </p>
          <p>
            Illustration Generator
            {' '} 
            {i18next.t('imagePhoto05', 'is a tool to generate a illustration image from a input image.')}
            {' '}
            {i18next.t('imagePhoto06', 'By using AI-technologies, input picture image can be converted into illustarion image.')}
          </p>

        </p>
        <p>
          <div>
            <strong>Draw Dreamer</strong>
          </div>
          <a
            href="https://kura-draw-dreamer.spaceeight.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            Draw Dreamenr
          </a>{" "}
          {i18next.t(
            "kuraStorage24",
            "is a simple whiteboard to generate images. User can generate full color image from drawed sketch image."
          )}
          <br />
          {i18next.t(
            "kuraStorage25",
            "Advanced AI technologies allow to generate images by combining drawed image with prompt description."
          )}
          <p>
            <div>
              <strong>
                Text Prompt Image Generation (in Draw Dreamer)
              </strong>
            </div>
            Draw Dreamer 
            {' '}
            {i18next.t('imagePhoto07', 'also has a tool to generate images from text input without drawing sketch.')} 
          </p>
          <p>
            <div>
              <strong>
                Image to Image Generation (in Draw Dreamer)
              </strong>
            </div>
            <div>
              Image to image generation 
              {' '}
              {i18next.t('imagePhoto12', 'allows users to manipulate images based on text prompt inputs.')}
            </div>
            <div>
              {i18next.t('imagePhoto13', 'By using input image as a guide for image generation, it generate new image that matches with text prompt input, and keep overall image structure.')}
            </div>
          </p>
          <p>
            <div>
              <strong>
                Image Stylizer (in Draw Dreamer)
              </strong>
            </div>
            <div>
              Image Stylizer{' '}
              {i18next.t('imagePhoto16', 'is a tool to generate new input image that has styles similar to style images. it is useful to create images with different styles.')}
            </div>
            <div>
              {i18next.t('imagePhoto17', 'Images with more diverse styles can be generated by using multiple style images.')}
            </div>
            </p>
          <p>
            <div>
              <strong>
                Image Refiner (in Draw Dreamer)
              </strong>
            </div>
            <div>
              Image Refiner 
              {' '}
              {i18next.t('imagePhoto14', 'is a tool to generate higher resolution image from small or unclear image.')}
            </div>
            <div>
              {i18next.t('imagePhoto15', 'By using input image as a guide for image generation, Image Refiner generate refined input image.')}
            </div>
          </p>
        </p>
        <p>
          <div>
            <strong>Image Resizer</strong>
          </div>
          <a
            href="https://kura-image-photo.spaceeight.net/image-resizer"
            target="_blank"
            rel="noopener noreferrer"
          >
            Image Resizer
          </a>{" "}
          {i18next.t(
            "kuraStorage29",
            "is a tool to resize multiple image files."
          )}
        </p>
        <p>
          <div>
            <strong>Image Fusion</strong>
          </div>
          <a
            href="https://kura-image-photo.spaceeight.net/image-fusion"
            target="_blank"
            rel="noopener noreferrer"
          >
            Image Fusion
          </a>{" "}
          {i18next.t('imagePhoto08', 'is an AI-assisted image generation tool.')}
          {' '}
          {i18next.t('imagePhoto09', 'User can generate a fused image from two input images (base image and style image).')}
          <p>
          {i18next.t('imagePhoto10', 'By transfering styles of style image to base image, style image-like base image is generated.')}
          </p>

        </p>
        <p>
          <div>
            <strong>Image Combiner</strong>
          </div>
          <a
            href="https://kura-image-photo.spaceeight.net/image-combiner"
            target="_blank"
            rel="noopener noreferrer"
          >
            Image Combiner
          </a>{" "}
          {i18next.t('imagePhoto11', 'is a tool to combine two input images and generate a new mixed image.')}
        </p>
      </div>
    </Fragment>
  );
}

export default KuraImagePhoto;
