import React from "react";
import { Fragment, useState } from "react";
import i18next from "i18next";

import StellarSpace from "./StellarSpace";
import StellarNftPlace from "./StellarNftPlace";

import { marks } from "../../../../utils/marks";
// import classes from "../HomeCodingContents.module.css";
import classes from './StellarBlockchain.module.css';

function StellarBlockchain(props) {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <Fragment>
      <ul>
        <li 
        // className={classes.blockchainDetailButton}
        //   onClick={() => {
        //     setShowDetail(!showDetail);
        //   }}
        >
          <strong>
            {i18next.t('blockchain01', 'Blockchain services on')}
            {' '}Stellar Network
            {' '}
            {/* (show detail
            {' '}
            <span>
              {marks.downArrowMark}
            </span>
            ) */}
          </strong>
          <ul>
            <li>
              {/* <p>
                {i18next.t('blockchain02', 'Blockchain services provide services on blockchain based')}
                {' '}Stellar network.
              </p> */}
              <p>
                {i18next.t('blockchain09', 'Services inclede Decentralized exchange (DEX), Liquidity pool share, News, Deposit currency, NFT platform')}
                
              </p>
              <p>
                <strong className={classes.blockchainDetailButton}
                  onClick={() => {
                    setShowDetail(!showDetail);
                  }}>
                  (show detail
                  {' '}
                  <span>
                    {/* <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path></svg> */}
                    {marks.downArrowMark}
                  </span>
                  )
                </strong>
              </p>
            </li>
          </ul>
        </li>
      </ul>

      {showDetail && (
        <ul>
          <li><StellarSpace /></li>
          <li><StellarNftPlace /></li>
        </ul>
      )}
      <br />
    </Fragment>
  );
}

export default StellarBlockchain;
